<template>
  <div class="main"
       ref="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search"
           v-if="currentTabIndex===0||currentTabIndex===1">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <Select v-model="facilityBigType"
                    placeholder="设施大类"
                    clearable
                    @on-change="onChangeBigType"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in facilityBigTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <Select v-model="facilitySmallType"
                    placeholder="设施小类"
                    clearable
                    :disabled="!facilityBigType"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in facilitySmallTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <MyTreeSelect :value="name"
                          placeholder="设施名称"
                          clearable
                          class="m-r-10"
                          style="width:160px"
                          :data="nameArr"
                          search
                          checked
                          @onCheckChange="onChangeName">
            </MyTreeSelect>
            <Select v-model="facilityStatus"
                    placeholder="状态"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in facilityStatusArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <MyTreeSelect :value="departName"
                          placeholder="维护单位"
                          clearable
                          class="m-r-10"
                          style="width:160px"
                          :data="departMentTreeArr"
                          @onSelectChange="onChangeDepart">
            </MyTreeSelect>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/gardensArchives/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/gardensArchives/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-if="currentTabIndex===2">
        <Tabs v-model="currentAffiliateTabIndex"
              class="tabs m-b-15"
              key="child2">
          <TabPane v-for="item in affiliateTabArr"
                   :key="item.name"
                   :label="item.name"></TabPane>
        </Tabs>
      </template>
      <div class="flex">
        <Button type="primary"
                class="m-r-10"
                @click.stop="onClickAddModal"
                v-if="currentTabIndex != 4 && checkPermi(['/admin/gardensArchives/add'])">
          <Icon custom="i-icon icon-tianjia1"
                size="16"></Icon>
          添加
        </Button>
        <template v-if="currentTabIndex===0||currentTabIndex===1">
          <Dropdown v-if="checkPermi(['/admin/gardensArchives/export'])">
            <Button class="m-r-10">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="part">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('part')">
                  <Button>
                    导出部分
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="now">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('now')">
                  <Button>
                    导出本页
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="all">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('all')">
                  <Button>
                    导出全部
                  </Button>
                </ExportTemplate>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <ExportTemplate :path="getExportTemplatePath"
                          :data="getExportTemplateData"
                          v-if="checkPermi(['/admin/gardensArchives/export'])">
            <Button class="m-r-10">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出模板
            </Button>
          </ExportTemplate>
          <ReaderFile @uploadSuccess="onClickReader"
                      accept=".xls,.xlsx"
                      action='hss/tlfacilities/excelReader'
                      :type="getReaderType"
                      v-if="checkPermi(['/admin/gardensArchives/import'])">
            <Button class="m-r-10">
              <Icon custom="i-icon icon-xiazai"
                    size="16"></Icon>
              导入
            </Button>
          </ReaderFile>
          <Dropdown @on-click="onClickBatch"
                    v-if="checkPermi(['/admin/gardensArchives/edit','/admin/gardensArchives/delete'])">
            <Button class="m-r-10">
              <Icon custom="i-icon icon-xiugai1"
                    size="16"></Icon>
              批量操作
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="delete"
                            v-if="checkPermi(['/admin/gardensArchives/delete'])">批量删除</DropdownItem>
              <DropdownItem name="curingCycle"
                            v-if="checkPermi(['/admin/gardensArchives/edit'])">批量修改养护周期</DropdownItem>
              <DropdownItem name="technicalInformation"
                            v-if="checkPermi(['/admin/gardensArchives/edit'])">批量修改技术资料</DropdownItem>
              <DropdownItem name="setAlarm"
                            v-if="checkPermi(['/admin/gardensArchives/edit'])">批量设置报警参数</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
        <Dropdown @on-click="onClickBatch"
                  v-if="currentTabIndex===3 && checkPermi(['/admin/gardensArchives/delete'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-xiugai1"
                  size="16"></Icon>
            批量操作
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="delete"
                          v-if="checkPermi(['/admin/gardensArchives/delete'])">批量删除</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="flex">
        <div class="tree noScroll"
             :style="{height:tableHeight+50+'px'}"
             v-if="currentTabIndex===3 || currentTabIndex===4">
          <div class="tree-title">设备类型</div>
          <ul class="tree-list">
            <li class="tree-item"
                :class="{active:currentTreeId===item.id}"
                v-for="item in facilityBigTypeArr"
                :key="item.id"
                @click.stop="onClickTreeItem(item.id)">{{item.name}}</li>
          </ul>
        </div>
        <div class="table">
          <template v-if="currentTabIndex === 2||currentTabIndex === 0||currentTabIndex === 1">
            <Table ref="table"
                   :width="tableWidth"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex == 0">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/gardensArchives/edit']"
                          @click.stop="onClickEditGarden(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          @click.stop="onClickSetGarden(row)">设置</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/gardensArchives/delete']"
                          @click.stop="onClickDeleteGarden(row)">删除</Button>
                </template>
                <template v-if="currentTabIndex == 1">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/gardensArchives/edit']"
                          @click.stop="onClickEditTree(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          @click.stop="onClickSetTree(row)">设置</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/gardensArchives/delete']"
                          @click.stop="onClickDeleteTree(row)">删除</Button>
                </template>
                <template v-if="currentTabIndex == 2">
                  <template v-if="currentAffiliateTabIndex == 0">
                    <Button type="text"
                            class="tableOperaBtn"
                            v-hasPermi="['/admin/gardensArchives/edit']"
                            @click.stop="onClickEditTreeVarieties(row)">修改</Button>
                    <!-- 接口无字段，不确定是否有此功能 -->
                    <!-- <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickSetTreeVarieties(row)">禁用</Button> -->
                    <Button type="text"
                            class="tableOperaBtn"
                            v-hasPermi="['/admin/gardensArchives/delete']"
                            @click.stop="onClickDeleteTreeVarieties(row)">删除</Button>
                  </template>
                  <template v-if="currentAffiliateTabIndex == 1">
                    <Button type="text"
                            class="tableOperaBtn"
                            v-hasPermi="['/admin/gardensArchives/edit']"
                            @click.stop="onClickEditConserve(row)">修改</Button>
                    <!-- 接口无字段，不确定是否有此功能 -->
                    <!-- <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickSetConserve(row)">禁用</Button> -->
                    <Button type="text"
                            class="tableOperaBtn"
                            v-hasPermi="['/admin/gardensArchives/delete']"
                            @click.stop="onClickDeleteConserve(row)">删除</Button>
                  </template>
                </template>
              </template>
            </Table>
          </template>
          <!-- <Button type="text"
                  class="tableOperaBtn"
                  v-hasPermi="['/admin/gardensArchives/edit']"
                  @click.stop="onClickEditConserve(row)">修改</Button> -->
          <!-- 接口无字段，不确定是否有此功能 -->
          <!-- <Button type="text"
                        class="tableOperaBtn"
                        @click.stop="onClickSetConserve(row)">禁用</Button> -->
          <!-- <Button type="text"
                  class="tableOperaBtn"
                  v-hasPermi="['/admin/gardensArchives/delete']"
                  @click.stop="onClickDeleteConserve(row)">删除</Button> -->

          <template v-else>
            <Table ref="table"
                   :width="tableWidths"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex===3">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/gardensArchives/edit']"
                          @click.stop="onClickEditFacilityType(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/gardensArchives/delete']"
                          @click.stop="onClickDeleteFacilityType(row)">删除</Button>
                </template>
                <template v-if="currentTabIndex === 4">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/gardensArchives/edit']"
                          @click.stop="onClickEditAlarm(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/gardensArchives/edit']"
                          @click.stop="onClickStatusAlarm(row)">{{row.status==1?'禁用':'恢复'}}</Button>
                </template>
              </template>
            </Table>
          </template>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>

    <!-- 添加园林绿地 -->
    <GardensModal v-model="gardensVisible"
                  :dataId.sync="gardensId"
                  :width="drawerWidth"
                  @onClickConfirm="getList" @onChange='onChangClearRowStyle'></GardensModal>

    <!-- 添加名木古树 -->
    <GardensTreeModal v-model="gardensTreeVisible"
                      :dataId.sync="gardensTreeId"
                      @onClickConfirm="getList" @onChange='onChangClearRowStyle'></GardensTreeModal>
    <!-- 设施状态 -->
    <FacilityStatusModal v-model="facilityStatusVisible"
                         :status="facilityStatus"
                         :item.sync="facilityStatusItem"
                         @onClickConfirm="getList" @onChange='onChangClearRowStyle'></FacilityStatusModal>

    <!-- 添加树种 -->
    <GardensTreeVarietiesModal v-model="gardensTreeVarietiesVisible"
                               :dataId.sync="gardensTreeVarietiesId"
                               @onClickConfirm="getList" @onChange='onChangClearRowStyle'></GardensTreeVarietiesModal>

    <!-- 添加养护信息 -->
    <GardensMaintainModal v-model="gardensMaintainVisible"
                          :dataId.sync="gardensMaintainId"
                          @onClickConfirm="getList" @onChange='onChangClearRowStyle'></GardensMaintainModal>

    <!-- 园林档案详情 -->
    <GardensDetailModal :dataId.sync="gardensDetailId"
                        v-model="gardensDetailVisible" @onChange='onChangClearRowStyle'></GardensDetailModal>

    <!-- 绿树档案详情 -->
    <GardensTreeDetailModal :dataId.sync="gardensTreeDetailId"
                            v-model="gardensTreeDetailVisible" @onChange='onChangClearRowStyle'></GardensTreeDetailModal>

    <!-- 查看地址 -->
    <PositionDetailModal v-model="positionDetailVisible"
                         :address="positionAddress"
                         :lng="positionLng"
                         :lat="positionLat" @onChange='onChangClearRowStyle'></PositionDetailModal>

    <!-- 绑定设备 -->
    <BindEquipmentModal v-model="bindEquipmentVisible"
                        :item.sync="bindEquipmentObject"
                        @onClickConfirm="getList" @onChange='onChangClearRowStyle'></BindEquipmentModal>

    <!-- 点击设置，修改设施报警参数 -->
    <ParameterListModal :dataId.sync="parameterListId"
                        :faclBigType.sync="parameterListFaclBigType"
                        v-model="parameterListVisible"
                        thresholdType="tree" @onChange='onChangClearRowStyle'></ParameterListModal>

    <!-- 设施报警参数tab中的修改按钮 -->
    <ParameterModal v-model="parameterVisible"
                    :type="parameterType"
                    :dataId.sync="parameterId"
                    thresholdType="facl"
                    @onClickConfirm="getList" @onChange='onChangClearRowStyle'></ParameterModal>
    <!-- 园林小类 -->
    <GardensTypeModal v-model="gardensTypeModalVisible"
                      :dataId.sync="gardensTypeModalId"
                      @onClickConfirm="getList" @onChange='onChangClearRowStyle'></GardensTypeModal>

    <!-- 批量修改养护周期、技术资料 -->
    <GardensBatchEditModal v-model="gardensBatchEditVisible"
                           :dataId.sync="gardensBatchEditId"
                           :type.sync="gardensBatchEditType"></GardensBatchEditModal>
  </div>
</template>

<script>
import GardensModal from '@/components/product/admin/modal/archives/GardensModal'
import GardensTreeModal from '@/components/product/admin/modal/archives/GardensTreeModal'
import GardensTreeVarietiesModal from '@/components/product/admin/modal/archives/GardensTreeVarietiesModal'
import GardensMaintainModal from '@/components/product/admin/modal/archives/GardensMaintainModal'
import GardensDetailModal from '@/components/product/admin/modal/archives/GardensDetailModal'
import GardensTreeDetailModal from '@/components/product/admin/modal/archives/GardensTreeDetailModal'
import PositionDetailModal from '@/components/product/main/template/PositionDetailModal'
import BindEquipmentModal from '@/components/product/admin/modal/archives/BindEquipmentModal'
import ParameterListModal from '@/components/product/admin/modal/archives/ParameterListModal'
import ParameterModal from '@/components/product/admin/modal/archives/ParameterModal'
import GardensTypeModal from '@/components/product/admin/modal/archives/GardensTypeModal'
import GardensBatchEditModal from '@/components/product/admin/modal/archives/GardensBatchEditModal'
import FacilityStatusModal from '@/components/product/admin/modal/archives/FacilityStatusModal'
import MyTreeSelect from '@/components/common/MyTreeSelect'
import { mapActions, mapGetters } from 'vuex'
import ReaderFile from '@/components/common/ReaderFile'
import ExportTemplate from '@/components/common/ExportTemplate'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    GardensModal,
    GardensTreeModal,
    GardensTreeVarietiesModal,
    GardensMaintainModal,
    GardensDetailModal,
    GardensTreeDetailModal,
    FacilityStatusModal,
    PositionDetailModal,
    BindEquipmentModal,
    ParameterListModal,
    ParameterModal,
    GardensTypeModal,
    GardensBatchEditModal,
    MyTreeSelect,
    ReaderFile,
    ExportTemplate
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '园林绿地'
        },
        {
          name: '名木古树'
        },
        {
          name: '附属资料'
        },
        {
          name: '园林类型设置'
        },
        {
          name: '报警参数设置'
        },
      ],
      facilityBigType: '',
      facilityBigTypeArr: [],
      currentTreeId: 0,
      facilityBigTypeData: [],
      facilitySmallType: '',
      facilitySmallTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '中转站'
        },
        {
          id: 2,
          name: '收集点'
        },
        {
          id: 3,
          name: '公厕'
        },
      ],
      nameId: '',
      name: '',
      nameArr: [],
      facilityStatus: '',
      facilityStatusArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '正常'
        },
        {
          id: 2,
          name: '废弃'
        },
        {
          id: 3,
          name: '维修'
        },
        {
          id: 4,
          name: '拆除'
        },
        {
          id: 5,
          name: '暂停'
        },
      ],
      departName: '',
      departId: '',
      currentAffiliateTabIndex: 0,
      affiliateTabArr: [
        {
          name: '树种设置'
        },
        {
          name: '养护知识'
        },
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,
      tableWidths: 0,
      tableLoading: false,
      columnsGardens: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设施名称',
          key: 'faclname',
          width: 150,
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickGardensDetail(row)
                }
              }
            }, row.faclname);
          }
        },
        {
          title: '设施编号',
          key: 'faclcode',
          width: 70,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备绑定',
          key: 'binds',
          width: 70,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickBindEquipment(row)
                }
              }
            }, row.binds ? row.binds.length : '点击绑定');
          }
        },
        {
          title: '设施类型',
          key: 'facltypename',
          width: 70,
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'facldeptname',
          width: 180,
          tooltip: true,
          align: 'center'
        },
        {
          title: '负责人',
          key: 'principalname',
          width: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '状态',
          key: 'faclstatus',
          width: 40,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用日期',
          key: 'startdate',
          width: 80,
          tooltip: true,
          align: 'center'
        },
        {
          title: '点位半径(m)',
          key: 'radii',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '地址',
          key: 'facladdrdetail',
          width: 160,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('Tooltip', {
              props: {
                content: row.facladdrdetail,
                maxWidth: "200"
              },
            }, [
              h('span', {
                style: {
                  color: '#25bb96',
                  cursor: 'pointer'
                },
                class: {
                  line1: true
                },
                on: {
                  click: () => {
                    this.onClickFacilityAddress(row)
                  }
                }
              }, row.facladdrdetail),
            ]);
          }
        },
        {
          title: '维护单位',
          key: 'mtadeptname',
          width: 180,
          tooltip: true,
          align: 'center'
        },
        {
          title: '最近处理',
          key: 'recentdeal',
          width: 70,
          tooltip: true,
          align: 'center'
        },
        {
          title: '处理超时提醒',
          key: 'dealovertime',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计运行时长',
          key: 'totalworktime',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '商圈/社区/小区/饭店',
          key: 'businessarea',
          width: 130,
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '附属设施',
        //   key: 'subfacl',
        //   width: 90,
        //   tooltip: true,
        //   align: 'center',
        //   render: (h, params) => {
        //     let row = params.row
        //     return h('span', {
        //       style: {
        //         color: '#25bb96',
        //         cursor: 'pointer'
        //       },
        //       class: {
        //         line1: true
        //       },
        //       on: {
        //         click: () => {
        //           this.onClickBindFacility(row)
        //         }
        //       }
        //     }, row.subfacl ? row.subfacl : '点击绑定');
        //   }
        // },
        {
          title: '操作',
          slot: 'operation',
          width: 130,
          align: 'center'
        }
      ],
      columnsTree: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设施名称',
          key: 'faclname',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickTreeDetail(row)
                }
              }
            }, row.faclname);
          }
        },
        {
          title: '设施编号',
          key: 'faclcode',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备绑定',
          key: 'binds',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickBindEquipment(row)
                }
              }
            }, row.binds ? row.binds.length : '点击绑定');
          }
        },
        {
          title: '设施类型',
          key: 'facltypename',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'facldeptname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '负责人',
          key: 'principalname',
          width: 70,
          tooltip: true,
          align: 'center'
        },
        {
          title: '状态',
          key: 'faclstatus',
          width: 70,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用日期',
          key: 'startdate',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '点位半径(m)',
          key: 'radii',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '地址',
          key: 'facladdrdetail',
          width: 160,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('Tooltip', {
              props: {
                content: row.facladdrdetail,
                maxWidth: "200"
              },
            }, [
              h('span', {
                style: {
                  color: '#25bb96',
                  cursor: 'pointer'
                },
                class: {
                  line1: true
                },
                on: {
                  click: () => {
                    this.onClickFacilityAddress(row)
                  }
                }
              }, row.facladdrdetail),
            ]);
          }
        },
        {
          title: '维护单位',
          key: 'mtadeptname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '最近处理',
          key: 'recentdeal',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '处理超时提醒',
          key: 'dealovertime',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计运行时长',
          key: 'totalworktime',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '商圈/社区/小区/饭店',
          key: 'businessarea',
          width: 130,
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '附属设施',
        //   key: 'subfacl',
        //   width: 90,
        //   tooltip: true,
        //   align: 'center',
        //   render: (h, params) => {
        //     let row = params.row
        //     return h('span', {
        //       style: {
        //         color: '#25bb96',
        //         cursor: 'pointer'
        //       },
        //       class: {
        //         line1: true
        //       },
        //       on: {
        //         click: () => {
        //           this.onClickBindFacility(row)
        //         }
        //       }
        //     }, row.subfacl ? row.subfacl : '点击绑定');
        //   }
        // },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      columnsAffiliateTree: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '树种',
          key: 'treespecname',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '科属',
          key: 'families',
          tooltip: true,
          align: 'center'
        },
        {
          title: '生长类型',
          key: 'growntype',
          tooltip: true,
          align: 'center'
        },
        {
          title: '标识',
          key: 'iconid',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.iconid) {
              return h('img', {
                attrs: {
                  src: require('../../../assets/common/gardens/' + row.iconid + '.png')
                },
                style: {
                  width: '26px',
                  height: '26px',
                }
              });
            } else {
              return h('span', '')
            }
          }
        },
        {
          title: '介绍说明',
          key: 'introduce',
          tooltip: true,
          align: 'center'
        },
        {
          title: '养护建议',
          key: 'maintenanceadvice',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '是否启用',
        //   key: 'status',
        //   tooltip: true,
        //   align: 'center',
        // },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      columnsAffiliateConserve: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '标题',
          key: 'title',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '类型',
          key: 'knowledgetype',
          tooltip: true,
          align: 'center'
        },
        {
          title: '适用范围',
          key: 'scope',
          tooltip: true,
          align: 'center'
        },
        {
          title: '摘要',
          key: 'curabstract',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '是否启用',
        //   key: 'status',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      columnsType: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设施大类',
          key: 'facilitietypeid',
          width: 200,
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '设施小类',
          key: 'itemtypename',
          width: 200,
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          maxWidth: 765,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      columnsSet: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '系统名称',
          key: 'sysname',
          width: 140,
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '报警名称',
          key: 'alarmname',
          width: 140,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设施大类',
          key: 'faclitypename',
          width: 140,
          tooltip: true,
          align: 'center'
        },
        {
          title: '阈值',
          key: 'threshold',
          minWidth: 60,
          maxWidth: 340,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已停用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          maxWidth: 340,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 园林绿地
      gardensVisible: false,
      gardensId: '',
      drawerWidth: 0,
      // 名木古树
      gardensTreeVisible: false,
      gardensTreeId: '',
      // 树种
      gardensTreeVarietiesVisible: false,
      gardensTreeVarietiesId: '',
      // 设施状态
      facilityStatusVisible: false,
      facilityStatus: '',
      facilityStatusItem: null,
      // 养护信息
      gardensMaintainVisible: false,
      gardensMaintainId: '',
      // 园林详情
      gardensDetailVisible: false,
      gardensDetailId: '',
      // 绿树详情
      gardensTreeDetailVisible: false,
      gardensTreeDetailId: '',
      // 查看地址
      positionDetailVisible: false,
      positionAddress: '',
      positionLng: 0,
      positionLat: 0,
      // 绑定设备
      bindEquipmentVisible: false,
      bindEquipmentObject: null,
      // 点击设置，修改设施报警参数
      parameterListVisible: false,
      parameterListId: '',
      parameterListFaclBigType: '',
      // 设施报警参数tab中的修改按钮
      parameterVisible: false,
      parameterId: '',
      parameterType: '',
      // 类型
      gardensTypeModalVisible: false,
      gardensTypeModalId: '',
      // 批量修改
      gardensBatchEditVisible: false,
      gardensBatchEditId: '',
      gardensBatchEditType: '',
    };
  },
  watch: {
    currentTabIndex (newVal) {
      this.resetSearchData()
      this.onClickSearch()
      if (newVal == 0) {
        let obj = this.facilityBigTypeData.find(item => {
          return item.name == '园林绿化'
        })
        this.facilityBigTypeArr = [obj]
        this.getFacilityNameList()
      } else if (newVal == 1) {
        let obj = this.facilityBigTypeData.find(item => {
          return item.name == '名木古树'
        })
        this.facilityBigTypeArr = [obj]
        this.getFacilityNameList()
      } else if (newVal == 3 || newVal == 4) {
        let arr = [{
          id: 0,
          name: '全部'
        }]
        this.facilityBigTypeArr = arr.concat(this.facilityBigTypeData)
        this.getFacilityNameList()
      }
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    currentAffiliateTabIndex () {
      this.onClickSearch()
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectVisitedIndexArr = [data.id]
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departMentTreeArr': 'getDepartmentTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsGardens
          break;
        case 1:
          columns = this.columnsTree
          break;
        case 2:
          switch (this.currentAffiliateTabIndex) {
            case 0:
              columns = this.columnsAffiliateTree
              break;
            case 1:
              columns = this.columnsAffiliateConserve
              break;
            default:
              break;
          }
          break;
        case 3:
          columns = this.columnsType
          break;
        case 4:
          columns = this.columnsSet
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
        case 1:
          path = this.$http.exportFacl
          break;
        default:
          break;
      }
      return path
    },
    getExportTemplatePath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
        case 1:
          path = this.$http.exportFaclTem
          break;
        default:
          break;
      }
      return path
    },
    getExportTemplateData () {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = { type: '园林' }
          break;
        case 1:
          data = { type: '古树' }
          break;
        default:
          break;
      }
      return data
    },
    getReaderType () {
      let type = ''
      switch (this.currentTabIndex) {
        case 0:
          type = '园林'
          break;
        case 1:
          type = '古树'
          break;
        default:
          break;
      }
      return type
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList'
    ]),
    init () {
      this.tableWidths = window.innerWidth - 490
      this.tableWidth = window.innerWidth - 240
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableWidths = window.innerWidth - 490
        this.tableWidth = window.innerWidth - 240
        this.tableHeight = window.innerHeight - 350
      }
      this.drawerWidth = this.$refs.main.offsetWidth
      this.updateDepartmentTreeList()
      this.getBigTypeAllList()
      this.onChangeBigType()
      this.getList()
      this.getFacilityNameList()
    },
    // 设施名称列表
    getFacilityNameList () {
      this.$http.getFacilityBigTypeAllList({
        type: 'tree'
      }).then(res => {
        if (res.code === 200) {
          let obj
          this.facilityBigTypeData = res.result
          if (this.currentTabIndex == 0) {
            obj = this.facilityBigTypeData.find(item => {
              return item.name == '园林绿化'
            })
          } else if (this.currentTabIndex == 1) {
            obj = this.facilityBigTypeData.find(item => {
              return item.name == '名木古树'
            })
            this.facilityBigTypeArr = [obj]
          } else if (this.currentTabIndex == 4) {
            let arr = [{
              id: 0,
              name: '全部'
            }]
            this.facilityBigTypeArr = arr.concat(this.facilityBigTypeData)
          }
          if (this.currentTabIndex == 0 || this.currentTabIndex == 1) {
            this.$http.getBindObjectTreeList({ treetype: obj.id }).then(res => {
              if (res.code === 200) {
                this.nameArr = res.result
              }
            })
          }
        }
      })

    },
    // 获取作业大类列表
    getBigTypeAllList () {
      this.$http.getFacilityBigTypeAllList({
        type: 'tree'
      }).then(res => {
        if (res.code === 200) {
          this.facilityBigTypeData = res.result
          if (this.currentTabIndex == 0) {
            let obj = this.facilityBigTypeData.find(item => {
              return item.name == '园林绿化'
            })
            this.facilityBigTypeArr = [obj]
          } else {
            let obj = this.facilityBigTypeData.find(item => {
              return item.name == '名木古树'
            })
            this.facilityBigTypeArr = [obj]
          }
        }
      })
    },
    // 作业大类回调
    onChangeBigType (value) {
      if (value) {
        let arr = [{
          id: 0,
          name: '全部'
        }]
        this.$http.getFacilitySmallTypeAllList({ facilitietypeid: value }).then(res => {
          if (res.code === 200) {
            this.facilitySmallTypeArr = arr.concat(res.result)
          }
        })
      } else {
        this.facilitySmallTypeArr = []
      }
      this.facilitySmallType = ''
      // this.nameId = ''
      this.name = ''
    },
    // 选择设施名称
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type !== 'depart') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      // this.nameId = idArr.join()
      this.name = valueArr.join()
    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 选择维护单位
    onChangeDepart (data) {
      this.departId = data.id
      this.departName = data.name
    },
    // 点击查看地址
    onClickFacilityAddress (row) {
      this.positionAddress = row.facladdrdetail
      let lnglat = row.facladdr.split(',').filter(item => item)
      if (lnglat.length < 2) return this.$Message.info('地址信息错误')
      this.positionLng = parseFloat(lnglat[0])
      this.positionLat = parseFloat(lnglat[1])
      this.positionDetailVisible = true
      this.selectVisitedArr.push(row)
    },
    // 园林绿地详情
    onClickGardensDetail (row) {
      this.gardensDetailVisible = true
      this.gardensDetailId = row.id
      this.selectVisitedArr.push(row)
    },
    // 修改园林绿地
    onClickEditGarden (row) {
      this.gardensVisible = true
      this.gardensId = row.id
      this.selectVisitedArr.push(row)
    },
    // 园林绿地设置按钮
    onClickSetGarden (row) {
      this.parameterListId = row.id
      this.parameterListVisible = true
      this.selectVisitedArr.push(row)
    },
    // 删除园林绿地
    onClickDeleteGarden (row) {
      this.selectVisitedArr.push(row)
      this.onDeleteGarden([row.id])
      
    },
    // 点击绑定设备
    onClickBindEquipment (row) {
      row.type = row.facltypeid
      row.item = row.facltypeitemid
      this.bindEquipmentObject = row
      this.bindEquipmentVisible = true
      this.selectVisitedArr.push(row)
    },
    // 删除记录
    onDeleteGarden (arr) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '是否解绑设备并删除?',
        onOk: () => {
          let params = {
            ids: arr.join()
          }
          this.$http.deleteBatchFacility(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.selectArr = []
              this.getList()
            }
          })
        }
      })
    },
    // 名木古树详情
    onClickTreeDetail (row) {
      this.gardensTreeDetailVisible = true
      this.gardensTreeDetailId = row.id
      this.selectVisitedArr.push(row)
    },
    // 修改设施状态
    onClickStatusFacility (name, row) {
      this.facilityStatus = name
      this.facilityStatusItem = row
      this.facilityStatusVisible = true
    },
    // 修改名木古树
    onClickEditTree (row) {
      this.gardensTreeVisible = true
      this.gardensTreeId = row.id
      this.selectVisitedArr.push(row)
    },
    // 名木古树设置按钮
    onClickSetTree (row) {
      this.parameterListId = row.id
      this.parameterListVisible = true
    },
    // 删除名木古树
    onClickDeleteTree (row) {
      this.onDeleteTree([row.id])
      this.selectVisitedArr.push(row)
    },
    // 删除记录
    onDeleteTree (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '是否解绑设备并删除?',
        onOk: () => {
          let params = {
            ids: arr.join()
          }
          this.$http.deleteBatchFacility(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.selectArr = []
              this.getList()
            }
          })
        }
      })
    },
    // 修改树种
    onClickEditTreeVarieties (row) {
      this.gardensTreeVarietiesVisible = true
      this.gardensTreeVarietiesId = row.id
      this.selectVisitedArr.push(row)
    },
    // 删除树种
    onClickDeleteTreeVarieties (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            id: row.id
          }
          this.$http.deleteTreeVarieties(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.selectArr = []
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改养护知识
    onClickEditConserve (row) {
      this.gardensMaintainVisible = true
      this.gardensMaintainId = row.id
      this.selectVisitedArr.push(row)
    },
    // 修改养护知识状态
    onClickSetConserve (row) {
      return
      if (row.status === '启用') {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0
            }
            this.$store.dispatch('createOrEditConserve', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditConserve', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      }
    },
    // 删除养护知识
    onClickDeleteConserve (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            id: row.id
          }
          this.$http.deleteConserve(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.selectArr = []
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 点击修改设施小类
    onClickEditFacilityType (row) {
      this.gardensTypeModalId = row.id
      this.gardensTypeModalVisible = true
      this.selectVisitedArr.push(row)
    },
    // 删除设施小类
    onClickDeleteFacilityType (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            id: row.id
          }
          this.$http.deleteFacilitySmallType(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.selectArr = []
              this.getList()
              this.$store.dispatch('updateFacilityBigTypeAllList', true)
            }
          })
          this.onChangClearRowStyle()
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改报警参数
    onClickEditAlarm (row) {
      this.selectVisitedArr.push(row)
      this.parameterVisible = true
      this.parameterType = row.sysname
      this.parameterId = row.id
    },
    // 修改报警参数状态
    onClickStatusAlarm (row) {
      this.selectVisitedArr.push(row)
      if (row.status == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              status: 0,
              thresholdtype: 'facl',
              name: row.alarmname,
              sysname: row.sysname,
              faclitypeid: row.faclitypeid,
            }
            this.$store.dispatch('editTableAlarmStatus', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              status: 1,
              thresholdtype: 'facl',
              name: row.alarmname,
              sysname: row.sysname,
              faclitypeid: row.faclitypeid,
            }
            this.$store.dispatch('editTableAlarmStatus', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
        })
      }
    },
    // 报警设置大类
    onClickTreeItem (id) {
      this.currentTreeId = id
      this.onClickSearch()
    },
    onClickSet () {

    },
    onChangeDate (date) {
      this.date = date
    },
    onClickBatch (name) {
      if (this.selectArr.length == 0) return this.$Message.info('请选择数据')
      switch (name) {
        case 'delete':
          switch (this.currentTabIndex) {
            case 0:
              this.onDeleteGarden(this.selectArr)
              break;
            case 1:
              this.onDeleteTree(this.selectArr)
              break;
            default:
              break;
          }
          break;
        // 批量修改养护周期
        case 'curingCycle':
          this.gardensBatchEditVisible = true
          this.gardensBatchEditId = this.selectArr.join()
          this.gardensBatchEditType = 'curingCycle'
          break;
        // 批量修改技术资料
        case 'technicalInformation':
          this.gardensBatchEditVisible = true
          this.gardensBatchEditId = this.selectArr.join()
          this.gardensBatchEditType = 'technicalInformation'
          break;
        case 'setAlarm':
          let firstTypeId = this.selectDataArr[0].facltypeid
          let boo = true
          this.selectDataArr.forEach(item => {
            if (item.facltypeid != firstTypeId) {
              boo = false
            }
          })
          if (boo) {
            this.parameterListId = this.selectArr.join()
            this.parameterListFaclBigType = firstTypeId
            this.parameterListVisible = true
          } else {
            this.$Message.info('所选数据设施类型不同！')
          }
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.selectDataArr = selection
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getGardensList()
          break;
        case 1:
          this.getTreeList()
          break;
        case 2:
          switch (this.currentAffiliateTabIndex) {
            case 0:
              this.getTreeVarietiesList()
              break;
            case 1:
              this.getConserveList()
            default:
              break;
          }
          break;
        case 3:
          this.getFacilitySmallTypeList()
          break;
        case 4:
          this.getAlarmList()
          break;
        default:
          break;
      }
    },
    // 获取园林绿地列表
    getGardensList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        type: '园林',
        facltypeid: this.facilityBigType,
        facltypeitemid: this.facilitySmallType,
        faclname: this.name,
        faclstatus: '',
        mtadeptId: this.departId,
      }
      if (this.facilityStatus) params.faclstatus = this.facilityStatus - 1
      this.tableLoading = true
      this.$http.getFacilityList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.factypelist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 获取名木古树列表
    getTreeList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        type: '古树',
        facltypeid: this.facilityBigType,
        facltypeitemid: this.facilitySmallType,
        faclname: this.name,
        faclstatus: '',
        mtadeptId: this.departId,
      }
      if (this.facilityStatus) params.faclstatus = this.facilityStatus - 1
      this.tableLoading = true
      this.$http.getFacilityList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.factypelist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 获取树种列表
    getTreeVarietiesList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        treespecname: "", //树种名称
        status: "", //状态
      }
      this.tableLoading = true
      this.$http.getTreeVarietiesList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.treespeclist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 获取养护知识列表
    getConserveList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        knowledgetype: "", //树种名称
        status: "", //状态
        title: ""
      }
      this.tableLoading = true
      this.$http.getConserveList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.treespeclist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 获取小类列表
    getFacilitySmallTypeList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        facilitietypeid: 'tree',
      }
      if (this.currentTreeId) params.facilitietypeid = this.currentTreeId
      this.tableLoading = true
      this.$http.getFacilitySmallTypeList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.factypeitemlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 获取报警列表
    getAlarmList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        thresholdtype: 'tree',
        faclitypeid: ''
      }
      if (this.currentTreeId) params.faclitypeid = this.currentTreeId
      this.tableLoading = true
      this.$http.getAlarmList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.thresholdlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击添加按钮
    onClickAddModal () {
      if (this.currentTabIndex == 0) {
        this.gardensVisible = true
      } else if (this.currentTabIndex == 1) {
        this.gardensTreeVisible = true
      } else if (this.currentTabIndex == 2) {
        if (this.currentAffiliateTabIndex == 0) {
          this.gardensTreeVarietiesVisible = true
        } else {
          this.gardensMaintainVisible = true
        }
      } else if (this.currentTabIndex == 3) {
        this.gardensTypeModalVisible = true
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      // 园林绿地
      this.facilityBigType = ''
      this.facilitySmallType = ''
      this.name = ''
      this.facilityStatus = ''
      this.departId = ''
      this.departName = ''
      this.onChangeBigType()
      this.currentAffiliateTabIndex = 0
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []
      
    },
    // 点击导出的下拉菜单
    onClickExport (value) {
      switch (this.currentTabIndex) {
        case 0:
          this.onClickGardensExport(value)
          break;
        case 1:
          this.onClickTreeExport(value)
          break;
        default:
          break;
      }
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            type: '园林',
            facltypeid: this.facilityBigType,
            facltypeitemid: this.facilitySmallType,
            faclname: this.name,
            faclstatus: '',
            mtadeptId: this.departId,
          }
          if (this.facilityStatus) params.faclstatus = this.facilityStatus - 1
          if (value == 'now') {
            data.idlist = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.idlist = this.selectArr
          } else {
            // data.idlist = []
          }
          break;
        case 1:
          data = {
            type: '古树',
            facltypeid: this.facilityBigType,
            facltypeitemid: this.facilitySmallType,
            faclname: this.name,
            faclstatus: '',
            mtadeptId: this.departId,
          }
          if (this.facilityStatus) params.faclstatus = this.facilityStatus - 1
          if (value == 'now') {
            data.idlist = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.idlist = this.selectArr
          } else {
            data.idlist = []
          }
          break;
        default:
          break;
      }
      return data
    },
    // 导入回调
    onClickReader (arr) {
      if (arr.length > 0) {
        this.$Message.info('导入成功')
        this.getList()
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
      display: flex;
      flex-direction: column;

      .tree-title {
        background: #f5f5f5;
        font-size: 14px;
        color: #25bb96;
        padding: 15px 0 15px 12px;
        font-weight: bold;
      }

      .tree-list {
        flex: 1;
        overflow: auto;
        .tree-item {
          padding: 0 8px;
          line-height: 36px;
          cursor: pointer;

          &:hover {
            background: #f0f3f7;
          }

          &.active {
            background: #f0f3f7;
          }
        }
      }
    }
    .table {
      // width: 20000px;
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>